<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
        <el-input type="text" :placeholder="info.placeholder" v-model="text" class="input_input" size="small"></el-input>
        <div class="code">
            <el-input type="text" placeholder="请输入验证码" v-model="code" size="small"></el-input>
            <div class="btn">发送验证码</div>
        </div>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            text:'',
            code:''
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
        }
        .code{
            margin-top: 10px;
            position: relative;
            .btn{
                position: absolute;
                top: 0px;
                right: 0;
                width: 100px;
                bottom: 0px;
                background-color: #fb373e;
                color:#ffffff;
                line-height: 32px;
                text-align: center;
                font-size: 13px;
                cursor: pointer;
            }
        }
    }
</style>
