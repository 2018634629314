<template>
  <div class="bannerControl">
    <div class="bot">
      <div class="bgColor">
        <p>背景颜色</p>
        <el-color-picker v-model="info.background"></el-color-picker>
        <el-button type="primary" @click="returnbgColor">重置</el-button>
      </div>
      <div class="bgColor">
        <p>选择边距</p>
        <el-radio-group v-model="info.has_margin">
          <el-radio label="0">无边距</el-radio>
          <el-radio label="1">左右边距</el-radio>
        </el-radio-group>
      </div>
      <p class="tips">
        * 建议尺寸{{ info.has_margin == '0' ? '750px' : '686px' }}*{{
          info.has_margin == '0' ? '350px' : '320px'
        }}
        上传图片不超过1M，支持png、bmg、jpeg、jpg、gif
      </p>
      <div class="listItem">
        <vuedraggable
          v-model="info.list"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true
          }"
        >
          <transition-group>
            <div class="itemBox" v-for="(item, index) in info.list" :key="index">
              <div class="left">
                <div class="leftL">
                  <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''"
                    >&lt;</span
                  >
                  <span
                    @click="moveClick(1, index, item)"
                    :class="index == info.list.length - 1 ? 'fcccc' : ''"
                    >></span
                  >
                </div>
                <div class="leftR">
                  <img :src="item.url" alt="" />
                </div>
              </div>
              <div class="right">
                <div>
                  <div class="right-top">
                    <div class="inputBox">
                      <el-input v-model="item.url" disabled placeholder="选择图片"></el-input>
                      <div @click="getIndex(index)">
                        <el-button type="primary" @click="chooseImg(index)">选择</el-button>
                      </div>
                    </div>
                  </div>
                  <div class="right-top">
                    <div class="inputBox">
                      <el-input v-model="item.path" disabled></el-input>
                      <el-button type="primary" @click="choosePage(index)">选择</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="deleteItem(index)" class="close">
                  <i :class="['iconfont', index != 0 ? 'icon-guanbi' : '']"></i>
                </div>
            </div>
          </transition-group>
        </vuedraggable>
      </div>
      <div @click="addBanner" class="addOne">新增一个</div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 选择图片弹框 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
      :maxSize="1024 * 1024"
    ></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import UploadSource from '@/components/uploadSource'
import ToPage from '@/views/addPages/toPage/index'
export default {
  props: ['info'],
  components: {
    vuedraggable,
    UploadSource,
    ToPage
  },
  data() {
    return {
      index: -1,
      toPageFlag: false,
      chooseImgFlag: false,
      itemIndex: -1
    }
  },
  methods: {
      changeStatus(val) {
      this.chooseImgFlag = val
    },
    returnbgColor() {
      this.info.background = '#ffffff'
    },
    getIndex(index) {
      this.index = index
    },
    addBanner() {
      if (this.info.list.length == 5) {
        this.$message({
          message: '最多只可以添加五张banner图哦~',
          type: 'error'
        })
        return
      }
      this.info.list.push({
        url: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
        path: '跳转页面'
      })
    },
    // 点击移动banner图位置
    moveClick(status, index, item) {
      if (status == 0) {
        // 向上移
        if (index == 0) {
          this.$message({
            message: '已经在最前面了~',
            type: 'error'
          })
          return
        }
        this.info.list.splice(index, 1)
        this.info.list.splice(index - 1, 0, item)
      } else {
        // 向下移
        if (index == this.info.list.length - 1) {
          this.$message({
            message: '已经在最后面了~',
            type: 'error'
          })
          return
        }
        this.info.list.splice(index + 2, 0, item)
        this.info.list.splice(index, 1)
      }
    },
    // 删除对应banner图
    deleteItem(index) {
      this.info.list.splice(index, 1)
    },
    // 选择图片
    chooseImg(index) {
      this.chooseImgFlag = true
    },
    // 选择跳转路径
    choosePage(index) {
      this.toPageFlag = true
      this.itemIndex = index
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr()
      })
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.info.list[this.itemIndex].path = item.wxroute
      this.toPageFlag = false
    },
    // 拿到图片
    getImgRes(imglist) {
      this.info.list[this.index].url = imglist[0].path
    }
  }
}
</script>

<style lang="less" scoped>
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.bannerControl {
  width: 100%;
  .bot {
    .bgColor {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        margin-right: 20px;
      }
      .el-color-picker/deep/.el-color-picker__trigger {
        width: 150px;
      }
    }
    .tips {
      font-size: 14px;
      color: #9a9a9a;
    }
    .listItem {
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 5px;
        /**/
      }
      .itemBox {
        width: 100%;
        display: flex;
        height: 100px;
        margin-top: 20px;
        align-content: center;
        .left {
          display: flex;
          .leftL {
            font-family: SimSun;
            width: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            span {
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              border-radius: 5px;
              display: block;
              border: 1px solid #333;
              font-weight: bold;
              cursor: pointer;
            }
            span:first-child {
              transform: rotate(90deg);
            }
            span:last-child {
              transform: rotate(90deg);
              margin-top: 10px;
            }
          }
          .leftR {
            height: 100%;
            overflow: hidden;
            margin-right: 15px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .right {
          flex: 1;
          & > div:first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .inputBox {
            width: 220px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            position: relative;
            p {
              width: 150px;
              margin-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #9e9e9e;
            }
          }
        }
        .close{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
      }
    }
    .addOne {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #3c76ff;
      border: 1px solid #3c76ff;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
</style>
