<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}} <span style="color:#999999;">（最多支持{{info.max_num}}张图片）</span></div>
      <div class="images">
          <ReadyUploadSource
            @getSource="getImg"
            @changeOrder="changeOrder"
            @removeThis="removeImageThis"
            :isMany="true"
            :manyPath="goodsimgList"
            :isManyMax="info.max_num"
            :maxSize="1024 * 1024"
            :showStyle="{
                width: '98px',
                height: '98px'
            }"
        ></ReadyUploadSource>
      </div>

  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        ReadyUploadSource,
    },
    props:['info'],
    data(){
        return {
            radio:'',
            goodsimgList:[]
        }
    },
    methods:{
        //获取商品图片
        getImg(val) {
            this.goodsimgList.push(...val);
            console.log( this.goodsimgList)
        },
        changeOrder(val){
             console.log(val)
        },
        removeImageThis(index) {
            this.goodsimgList.splice(index, 1);
        },
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
        .images{
            margin-top: 10px;
        }
        /deep/.sourceList {
            &> div > span{
                flex-wrap: wrap;
            }
            .item:nth-child(3n+3){
                margin-right: 0;
            }
        }
    }
</style>
