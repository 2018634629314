<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
       <el-date-picker v-model="value1" type="date" placeholder="请选择日期" class="input_input" size="small" :default-value="info.default_date*1" value-format="timestamp">></el-date-picker>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            value1:'',
        }
    },
    watch:{
        'info.default_date'(val){
            this.value1 = val*1
        }
    },
    created () {
        if(this.info.default_date){
            this.value1 = this.info.default_date*1
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
    }
</style>
