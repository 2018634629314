<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
        <el-radio-group v-model="radio" class="input_input" size="small">
            <el-radio :label="info.radios[index]" v-for="(item,index) in info.radios" :key="index"></el-radio>
        </el-radio-group>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            radio:''
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px 0px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
        .el-radio{
            margin-bottom: 10px;
        }
    }
</style>
