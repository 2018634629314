<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
       <el-date-picker class="input_input" size="small"
        v-model="value1" :default-value="[info.start_time*1,info.end_time*1]"
        type="daterange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp">>
        </el-date-picker>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            value1:[],
        }
    },
    watch:{
        'info.start_time'(val){
            let end_time = this.value1[1]
            this.value1 = [val*1,end_time]
        },
        'info.end_time'(val){
            let start_time = this.value1[0]
            this.value1 = [start_time,val*1]
        }
    },
    created () {
        if(this.info.start_time){
            let end_time = this.value1[1]
            this.value1 = [this.info.start_time*1,end_time]
        }
         if(this.info.end_time){
            let start_time = this.value1[0]
            this.value1 = [start_time,this.info.end_time*1]
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
    }
</style>
