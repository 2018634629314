import { render, staticRenderFns } from "./bannercontrol.vue?vue&type=template&id=37745005&scoped=true&"
import script from "./bannercontrol.vue?vue&type=script&lang=js&"
export * from "./bannercontrol.vue?vue&type=script&lang=js&"
import style0 from "./bannercontrol.vue?vue&type=style&index=0&id=37745005&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37745005",
  null
  
)

export default component.exports