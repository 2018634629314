<template>
    <div class="carousel" :style="{'background':info.background,'padding':info.has_margin=='0'?'0px':'0 13px'}">
        <el-carousel :style="{'border-radius':info.has_margin=='0'?'0px':'10px'}" :height="info.has_margin=='0'?'180px':'170px'">
            <el-carousel-item v-for="(item,index) in info.list" :key="index">
                <img :src="item.url" alt="img_url" :style="{'height':info.has_margin=='0'?'180px':'170px'}">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
        }
    },
    methods:{
    }
}
</script>

<style lang="less" scoped>
    .carousel{
        position: relative;
        z-index: 20;
        img{
            width: 100%;
        }
    }
</style>
